import React from 'react';
import { useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';
import { App } from '~/components/ThankYouWidget/Widget/components/app/app';
import { TPAComponentsProvider } from 'wix-ui-tpa';

const Widget: React.FC<WidgetProps<{}>> = () => {
  const { isMobile, isRTL } = useEnvironment();
  return (
    <TPAComponentsProvider
      // eslint-disable-next-line react-hooks/rules-of-hooks
      value={React.useMemo(() => ({ mobile: isMobile, rtl: isRTL }), [isMobile, isRTL])}
    >
      <App />
    </TPAComponentsProvider>
  );
};

export default Widget;
