import React from 'react';
import { Button, Text } from 'wix-ui-tpa/cssVars';
import { useTranslate } from '../../../hooks/use-translate';
import { classes } from './app.st.css';

export const App: React.FC = () => {
  const t = useTranslate();
  return (
    <div className={classes.root}>
      <Text>{t('thank-you.title')}</Text>
      <Text className={classes.body}>{t('thank-you.body')}</Text>
      <Button className={classes.cta}>{t('thank-you.cta')}</Button>
    </div>
  );
};
